<template>
  <div>
    <dossier-info-header-card class="no-float margin" :dossier-info="headerCardInfo"> </dossier-info-header-card>
    <crud-editor
      ref="editor"
      :is-edit="true"
      :definition="definition"
      :fetch-by-id="FetchById"
      :disabled="true"
      :save="saveData"
      :return-to="returnToPage"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
    />
    <div class="agreementform">
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="Roles.Consultant"
          :display-subject="true"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="Roles.Manager"
          :display-subject="false"
          :allow-save="allowSaveManager"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showBoardAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="Roles.Board"
          :display-subject="false"
          :allow-save="allowSaveBoard"
          :loading="loading"
        ></AgreementTable>
      </div>
    </div>
    <div class="app-container">
      <label title="Historie">Activiteiten logboek</label>
      <el-table ref="agreementlog" :data="agreements" stripe>
        <el-table-column property="agreementByText" :label="$t('common.role')" width="200" />
        <el-table-column property="userName" :label="$t('common.username')" width="200" />
        <el-table-column
          property="agreementActionText"
          :formatter="actionFormatter"
          :label="$t('loan.agreementAction')"
          width="300"
        />
        <el-table-column property="date" :label="$t('common.date')" width="125" />
        <el-table-column property="time" :label="$t('common.time')" width="125" />
        <el-table-column property="remarks" :label="$t('common.remarks')" />
      </el-table>
    </div>
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';
import DossierInfoHeaderCard from '@/pages/dossiers/DossierInfoHeaderCard';
import AgreementTable from '@/components/DSE/AgreementTable';
import { Roles } from './Roles/Roles.js';
export default {
  components: {
    CrudEditor,
    DossierInfoHeaderCard,
    AgreementTable
  },
  mixins: [formMixin],
  props: {
    allowsave: Boolean,
    returnToPage: String
  },
  data() {
    return {
      headerCardInfo: {},
      Roles: Roles,
      loanInfo: {},
      loanId: 0,
      loanAgreements: [],
      description: '',
      allowSaveManager: false,
      allowSaveBoard: false,
      agreementby: 0,
      agreementfrom: 0,
      returntopage: '',
      showBoardAgreement: false
    };
  },
  computed: {
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    agreementType() {
      return this.agreementtype;
    },
    loanDescription() {
      return this.description;
    },
    saveAllowed() {
      return this.allowSave;
    },
    agreements() {
      return this.loanAgreements;
    }
  },
  methods: {
    async FetchById(id) {
      const loan = await this.fetchById(id, this);
      return loan;
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    }
  }
};
</script>

<style scoped>
.no-float {
  float: none;
}

.margin {
  margin: 10px;
}
</style>
<style>
.remark {
  margin: 5px;
  margin-left: 20px;
  height: 4em;
  width: 90%;
}

.el-switch__label.is-active {
  color: blue;
}
h4 {
  margin: 5px;
}
.el-input.is-disabled {
  color: black;
}
.el-input.is-disabled .el-input__inner {
  color: black;
}
.el-textarea.is-disabled .el-textarea__inner {
  color: black;
}
</style>
